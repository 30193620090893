<template>
  <div>
    <TagbyFilterV2
      :initialParams="filterParams"
      :categoryOptions="categoryOptions"
      @search="search"
    >
      <template #buttons>
        <BButton
          variant="outline-success"
          class="mr-1"
          :disabled="isDownloading"
          @click="downloadExcel"
        >
          <BSpinner
            v-if="isDownloading"
            small
          />
          엑셀 다운로드
        </BButton>
      </template>
    </TagbyFilterV2>
    <TagbyList
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(has_contacted)="data">
        <BFormCheckbox
          :checked="data.value"
          switch
          @input="setHasContacted(data.item.idx, $event)"
        />
      </template>
      <template #cell(service)="data">
        <div style="width: 100px">
          {{ data.item.content == null ? null : data.item.content.service }}
        </div>
      </template>

      <template #cell(content)="data">
        <div style="width: 350px">
          {{ resolveContent(data.value) }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
  BButton,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import useInitialize from './useInitialize'
import useItems from './useItems'
import useDonwloadExcel from './useDonwloadExcel'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDateColumn from '@/components/TagbyDateColumn.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import useSetHasContacted from './useSetHasContacted'

export default defineComponent({
  components: {
    TagbyFilterV2,
    BLink,
    BButton,
    BSpinner,
    BFormCheckbox,
    TagbyList,
    TagbyDateColumn,
    TagbyDatetimeColumn,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,
    } = useItems()
    fetchItems()

    const {
      downloadExcel,
      isDownloading,
    } = useDonwloadExcel()

    const resolveContent = content => {
      if (content.contents) {
        return content.contents
      }
      if (content.question) {
        return content.question
      }
      return null
    }

    const {
      setHasContacted,
    } = useSetHasContacted()

    return {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,

      downloadExcel,
      isDownloading,

      resolveContent,
      setHasContacted,
    }
  },
})
</script>
