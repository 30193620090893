import moment from 'moment'
import data from './data'
import actions from './actions'
import useToast from '@/utils/toast'
import downloadFile from '@/utils/downloadFile'

export default () => {
  const { makeToast } = useToast()

  const downloadExcel = () => {
    data.isDownloading.value = true
    actions.getExcel({
      ...data.searchParams.value,
    }).then(response => {
      const fileName = `lead_${moment().format('YYYYMMDDHHmmss')}.xlsx`
      const file = new File([response.data], fileName, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      downloadFile(file, fileName)
    }).catch(() => {
      makeToast('danger', '엑셀을 다운로드하는데 실패 했습니다')
    }).finally(() => {
      data.isDownloading.value = false
    })
  }

  return {
    isDownloading: data.isDownloading,
    downloadExcel,
  }
}
