import data from './data'
import actions from './actions'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'

export default () => {
  const { makeToast } = useToast()
  const categoryOptions = [
    {
      label: 'SUBJECT',
      paramName: 'subject_list',
      format: 'checkbox',
      options: [
        { text: 'WEBINAR', value: 'WEBINAR' },
        { text: 'WEBINAR_REPLAY', value: 'WEBINAR_REPLAY' },
        { text: 'REWARD_REPORT', value: 'REWARD_REPORT' },
        { text: 'RESTAURANT_WEBINAR', value: 'RESTAURANT_WEBINAR' },
        { text: 'MARKETING', value: 'MARKETING' },
        { text: 'INF_PERFORMANCE_WEBINAR', value: 'INF_PERFORMANCE_WEBINAR' },
        { text: 'INF_MARKETING_WEBINAR', value: 'INF_MARKETING_WEBINAR' },
      ],
    },
  ]

  const fetchItems = () => {
    data.isBusy.value = true
    actions.fetchItems({
      ...data.searchParams.value,
    }).then(response => {
      const resData = response.data
      data.items.value = resData.data
      data.totalRows.value = resData.page_info.total_count
      data.filterParams.value.total = resData.page_info.total_count
      replaceRouterQuery(data.searchParams.value)
    }).catch(() => {
      makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
    }).finally(() => {
      data.isBusy.value = false
    })
  }

  const search = params => {
    data.currentPage.value = 1
    data.filterParams.value = {
      ...params,
    }
    syncronizeParamsWithUrl(data.searchParams.value)
    fetchItems()
  }

  const tableColumns = [
    { key: 'idx', label: 'idx' },
    { key: 'has_contacted', label: '컨설팅여부' },
    { key: 'email', label: 'email' },
    { key: 'name', label: 'name' },
    { key: 'company_name', label: 'company_name' },
    { key: 'phone', label: 'phone' },
    { key: 'job_position', label: 'job_position' },
    { key: 'subject', label: 'subject' },
    { key: 'service', label: 'service' },
    { key: 'content', label: 'content' },
    { key: 'created_at', label: 'created_at' },
  ]

  const changePage = params => {
    data.currentPage.value = params.page
    fetchItems()
  }

  return {
    categoryOptions,
    ...data,
    fetchItems,
    tableColumns,
    search,
    changePage,
  }
}
