import data from './data'
import actions from './actions'
import useToast from '@/utils/toast'

export default () => {
  const { makeToast } = useToast()
  const setHasContacted = (contactIdx, value) => {
    actions.setHasContacted({
      contact_idx: contactIdx,
      has_contacted: value,
    }).then(() => {
      makeToast('primary', '컨설팅여부를 수정하는데 성공했습니다')
    }).catch(() => {
      makeToast('danger', '컨설팅여부를 수정하는데 실패했습니다')
    })
  }

  return {
    setHasContacted,
  }
}
